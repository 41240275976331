.posts-container {
    width: 100%; 
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
}

.post {
    width: 800px;
}

.post h2 {

}

.post .p {

}
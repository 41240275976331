body {
    margin: 0;
    padding: 0;
}

ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.message-container {
    display: flex;
    height: 50vh;
    width: 100%;
    margin: 64px 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.template-header {
    height: 60px;
    background-color: #043369;
    color: #FFF;
}

.template-body {
    display: flex;
    color: #333;
}
